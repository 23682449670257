/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:85cc960c-e2fa-41b5-9de9-8254f6b5f609",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_Gdqb6kj3x",
    "aws_user_pools_web_client_id": "6a9c9g8p4ea9qu59go72s8d4fm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "websiteformatt-videos14816-staging",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://cqcrw53cv5d37pffd4hetkz4vq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6jopykkjongudlh5rnqsjrpnne"
};


export default awsmobile;
